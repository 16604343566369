import React from "react";
import bg from "../../../public/images/keyvisual/services.jpg";
import bg1 from "../../../public/images/services1.jpg";
import bg2 from "../../../public/images/services2.jpg";
import bg3 from "../../../public/images/services3.jpg";
import { SectionTitle } from "./SectionTitle";

export const ServicesSec = () => {
    return (
        <section className="services" id="services">
            <div className="inner">
                <SectionTitle titleName="事業内容" sideLine={true}></SectionTitle>
                <div className="contents">
                    <div className="content setHleft">
                        <div className="texts">
                            <h3>■カスタマーサポート</h3>
                            <div>
                                <p>・メール振分け</p>
                                <p>・メール対応</p>
                                <p>・コールセンター代行　等</p>
                                <p>主にインバウンドでのカスタマーサポートを24時間対応</p>　
                            </div>
                        </div>
                        <img src={bg1} alt="" />
                    </div>
                    <div className="content setHright">
                        <img src={bg2} alt="" />
                        <div className="texts">
                            <h3>■データ監視</h3>
                            <div>
                                <p>・WEBサーバー、ルーター等のアクセス</p>
                                <p>・WEBサイトでの不正利用　等</p>
                                <p>
                                    <span className="nowrap">WEBサイトでのアクセスログ、</span>
                                    <span className="nowrap">不正利用や不正決済</span>
                                    <span className="nowrap">そのほか様々なシステム及び</span>
                                    <span className="nowrap">データ監視の代行を24時間対応</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="content setHleft">
                        <div className="texts">
                            <h3>■デジタルアート販売</h3>
                            <div>
                                <p>・高精細画像販売</p>
                                <p>・キャラクターモデリングデータ販売</p>
                                <p>
                                    画像データは随時追加
                                    <br />
                                    他では再現できないモデリングデータをご提供
                                </p>
                                　
                            </div>
                        </div>
                        <img src={bg3} alt="" />
                    </div>
                </div>
            </div>
            <img src={bg} alt="" className="bg js-rellax" data-rellax-percentage="0.5" />
        </section>
    );
};
