import React, { useEffect } from "react";
import Logo from "../../../public/images/css-icon-title-w.png";
import { Cross } from "akar-icons";
import { Link } from "react-router-dom";

const OPTIONS={
    headerChangeScrollAmount:200
}

export const Header = ({page,scrolledClass,setScrollTarget,UNSCROLLABLE}) => {

    useEffect(() => {
        if (scrolledClass == true) {
            const headerElem = document.querySelector("header");
            headerElem.classList.add("scrolled");
        }
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", handleScroll, { passive: true });
        };
    }, []);

    const handleScroll = () => {
        const headerElem = document.querySelector("header");
        if (window.scrollY > OPTIONS.headerChangeScrollAmount || scrolledClass == true) {
            headerElem.classList.add("scrolled");
        } else {
            headerElem.classList.remove("scrolled");
        }
    };

    const hamburgerOpen = () => {
        document.querySelector(".hamburgerMenu").classList.add("isShow");
        if (UNSCROLLABLE) {
            document.querySelector("body").classList.add("unscrollable");
        }
    };
    const hamburgerClose = () => {
        document.querySelector(".hamburgerMenu").classList.remove("isShow");
        if (UNSCROLLABLE) {
            document.querySelector("body").classList.remove("unscrollable");
        }
    };
    //クリックイベントの伝搬を防ぐ
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    if (page == "home") {
        return (
            <>
            {/* ホームＰ通常 */}
                <header>
                    <div className="inner">
                        <a href="#">
                            <img src={Logo} alt="Logo" className="logo" />
                        </a>
                        <nav>
                            {/* ホームP、通常 */}
                            <ul>
                                <li>
                                    <a href="#top">ホーム</a>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <a href="#greetings">代表挨拶</a>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <a href="#companyProfile">会社概要</a>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <a href="#services">事業内容</a>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <a href="#recruit">採用情報</a>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <a href="#support">お問い合わせ</a>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <a href="#access">アクセス</a>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <Link to="/privacy">プライバシーポリシー</Link>
                                    <div className="underLine"></div>
                                </li>
                            </ul>
                        </nav>
                        <div className="hamburgerBtn" onClick={hamburgerOpen}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </header>
                {/* ホームＰ　展開後 */}
                <div className="hamburgerMenu" onClick={hamburgerClose}>
                    <nav onClick={stopPropagation}>
                        {/* ホームＰ，ハンバーガー */}
                        <ul>
                            <li onClick={hamburgerClose}>
                                <a href="#top">ホーム</a>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <a href="#greetings">代表挨拶</a>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <a href="#companyProfile">会社概要</a>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <a href="#services">事業内容</a>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <Link to="/recruit">採用情報</Link>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <Link to="/support">お問い合わせ</Link>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <a href="#access">アクセス</a>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <Link to="/privacy">プライバシーポリシー</Link>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <button className="close">
                                    <Cross strokeWidth={1} size={25} />
                                    <span> 閉じる</span>
                                </button>
                                <div className="underLine"></div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </>
        );
    } else if (page == "notFound") {
        return (
            <>
            {/* NotFoundP */}
                <header>
                    <div className="inner">
                        <Link to="/" onClick={() => setScrollTarget("home")}>
                            <img src={Logo} alt="Logo" />
                        </Link>
                    </div>
                </header>
            </>
        );
    } else {
        // ホームP以外
        return (
            <>
                <header>
                    <div className="inner">
                        <Link to="/">
                            <img src={Logo} alt="Logo" />
                        </Link>
                        <nav>
                            {/* ホームＰ以外通常 */}
                            <ul>
                                <li>
                                    <Link to="/">
                                        ホーム
                                    </Link>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <Link to="/" onClick={() => setScrollTarget("greetings")}>
                                        代表挨拶
                                    </Link>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <Link to="/" onClick={() => setScrollTarget("companyProfile")}>
                                        会社概要
                                    </Link>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <Link to="/" onClick={() => setScrollTarget("services")}>
                                        事業内容
                                    </Link>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <Link to="/recruit">採用情報</Link>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <Link to="/support">お問い合わせ</Link>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <Link to="/" onClick={() => setScrollTarget("access")}>
                                        アクセス
                                    </Link>
                                    <div className="underLine"></div>
                                </li>
                                <li>
                                    <Link to="/privacy">プライバシーポリシー</Link>
                                    <div className="underLine"></div>
                                </li>
                            </ul>
                        </nav>
                        <div className="hamburgerBtn" onClick={hamburgerOpen}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </header>
                {/* 展開後 */}
                <div className="hamburgerMenu" onClick={hamburgerClose}>
                    <nav onClick={stopPropagation}>
                        {/* ホームＰ，ハンバーガー */}
                        <ul>
                            <li onClick={hamburgerClose}>
                                <Link to="/">
                                    ホーム
                                </Link>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <Link to="/" onClick={() => setScrollTarget("greetings")}>
                                    代表挨拶
                                </Link>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <Link to="/" onClick={() => setScrollTarget("companyProfile")}>
                                    会社概要
                                </Link>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <Link to="/" onClick={() => setScrollTarget("service")}>
                                    事業内容
                                </Link>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <Link to="/recruit">採用情報</Link>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <Link to="/support">お問い合わせ</Link>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <Link to="/" onClick={() => setScrollTarget("access")}>
                                    アクセス
                                </Link>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <Link to="/privacy">プライバシーポリシー</Link>
                                <div className="underLine"></div>
                            </li>
                            <li onClick={hamburgerClose}>
                                <button className="close">
                                    <Cross strokeWidth={1} size={25} />
                                    <span> 閉じる</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </>
        );
    }
};
