import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-fade";

import { VantaRing } from "../vanta/VantaRing";
import { VantaBird } from "../vanta/VantaBird";
import { VantaGlobe } from "../vanta/VantaGlobe";

import img1 from "../../../public/images/img1.jpg";
import img2 from "../../../public/images/img2.jpg";
import img3 from "../../../public/images/img3.jpg";
import img4 from "../../../public/images/img4.png";


export const Swiperr = () => {
    return (
        <>
            <Swiper
                spaceBetween={1920}
                centeredSlides={true}
                effect={"fade"}
                loop={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, EffectFade]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <VantaGlobe></VantaGlobe>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <VantaBird></VantaBird>
                </SwiperSlide> */}
                {/* <SwiperSlide>
                    <VantaRing></VantaRing>
                </SwiperSlide> */}
                {/* <SwiperSlide>
                    <img src={img1} alt="img1" />
                </SwiperSlide> */}
                {/* <SwiperSlide>
                    <img src={img2} alt="img2" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={img3} alt="img3" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={img4} alt="img4" />
                </SwiperSlide> */}
            </Swiper>
        </>
    );
};
