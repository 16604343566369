import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { HomeP } from "./pages/HomeP";
import { PrivacyP } from "./pages/PrivacyP/PrivacyP";
import { SupportP } from "./pages/SupportP/SupportP";
import { NotFoundP } from "./pages/NotFoundP/NotFoundP";
import { ScrollTop } from "./components/ScrollTop";
import { RecruitP } from "./pages/RecruitP/RecruitP";
import { ManageP } from "./pages/manageP/ManageP";

const OPTIONS = {
    UNSCROLLABLE: false,
};

export const Router = ({ LOADING, isLoaded, debug }) => {

    debug.debuging(debug.enable, "Router.jsx", OPTIONS);

    const [scrollTarget, setScrollTarget] = useState("");
    return (
        <>
            <ScrollTop></ScrollTop>
            <Routes>
                <Route
                    path="/"
                    element={<HomeP UNSCROLLABLE={OPTIONS.UNSCROLLABLE} scrollTo={scrollTarget} LOADING={LOADING} isLoaded={isLoaded} debug={debug}/>}
                />
                <Route path="/recruit" element={<RecruitP UNSCROLLABLE={OPTIONS.UNSCROLLABLE} setScrollTarget={setScrollTarget} />} />
                <Route path="/privacy" element={<PrivacyP UNSCROLLABLE={OPTIONS.UNSCROLLABLE} setScrollTarget={setScrollTarget} />} />
                <Route path="/support" element={<SupportP UNSCROLLABLE={OPTIONS.UNSCROLLABLE} setScrollTarget={setScrollTarget} />} />
                <Route path="/manage" element={<ManageP UNSCROLLABLE={OPTIONS.UNSCROLLABLE} setScrollTarget={setScrollTarget} />} />
                <Route path="*" element={<NotFoundP UNSCROLLABLE={OPTIONS.UNSCROLLABLE} setScrollTarget={setScrollTarget} />} />
            </Routes>
        </>
    );
};
