import React from "react";
import bg from "../../../public/images/keyvisual/access2.jpg";
import NewGoogleMap from "../newGoogleMap";
import { SectionTitle } from "./SectionTitle";

export const AccessSec = () => {
    return (
        <>
            <section className="access" id="access">
                <div className="inner setV">
                    <SectionTitle titleName="アクセス" sideLine={true}></SectionTitle>
                    <div className="nonApiGoogleMap">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d728.8417733779887!2d141.3374407934908!3d43.05474631472872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f0b29922407ac8d%3A0xcae07602dc6d4b52!2z44Ki44Kv44Ki44K544OG44O844K4!5e0!3m2!1sja!2sjp!4v1727341882046!5m2!1sja!2sjp"
                            width="800"
                            height="400"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                    <div className="adress">
                        <p>株式会社シーエスエス</p>
                        <p>
                            〒060-0063　<span className="nowrap">札幌市中央区南3条西13丁目320-13　</span>
                            <span className="nowrap">アクアステージ４F</span>
                        </p>
                        <p>
                            TEL：011-211-0740　<span className="nowrap">FAX：011-211-0741</span>
                        </p>
                    </div>
                </div>
                <img src={bg} alt="" className="bg js-rellax" data-rellax-percentage="0.5" />
            </section>
        </>
    );
};
