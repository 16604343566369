import React from "react";

export const SectionTitle = ({sideLine,titleName}) => {
    return (
        <div className="title setV">
            {sideLine ? <div className="sideLine L"></div> : <></>}
            <h2>{titleName}</h2>
            {sideLine ? <div className="sideLine R"></div> : <></>}
        </div>
    );
};
