import React, { useState } from "react";
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";
import { Form } from "../../components/Form";
import SuccessModal from "../../components/modal/SuccessModal";
import { Link } from "react-router-dom";

export const SupportP = ({ UNSCROLLABLE, setScrollTarget }) => {
    const [isModalOpen, SetModal] = useState(false);

    return (
        <>
            <Header page={"privacy"} scrolledClass={true} setScrollTarget={setScrollTarget} UNSCROLLABLE={UNSCROLLABLE}></Header>
            <div className="pageContents supportP">
                <div className="inner">
                    <h2 className="pageTitle">お問い合わせ</h2>
                    <div className="CautionaryNote">
                        <h3>みなさまからのご質問・ご意見をお受けしております</h3>
                        <p>お問い合わせ内容を下のフォームに入力してください。</p>
                        <p>入力が終わりましたら、ページ下の【送信】ボタンを押してください。</p>
                        <ul>
                            <li>
                                このフォームにて取得した個人情報は、<Link to="/privacy">個人情報保護方針</Link>に基づいて厳重に管理します。
                            </li>
                            <li>お問い合わせ内容によってはご回答できない場合がございます。 あらかじめご了承ください。</li>
                        </ul>
                    </div>
                    <Form SetModal={SetModal}></Form>
                </div>
            </div>
            {isModalOpen && <SuccessModal SetModal={SetModal}></SuccessModal>}
            <Footer></Footer>
        </>
    );
};
