import React from "react";
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";
import { Link } from "react-router-dom";

export const RecruitP = ({ UNSCROLLABLE, setScrollTarget }) => {
    let recruitingStatus = null;
    process.env.REACT_APP_RECRUITING == "true" ? (recruitingStatus = true) : (recruitingStatus = false);
    return (
        <>
            <Header page={"recruit"} scrolledClass={true} setScrollTarget={setScrollTarget} UNSCROLLABLE={UNSCROLLABLE}></Header>
            <div className="pageContents recruitP">
                <div className="inner">
                    <h2 className="pageTitle">採用情報</h2>
                    <div className="infoBlock">
                        {recruitingStatus ? (
                            <>
                                {" "}
                                <ul className="recruitInfo">
                                    <li>
                                        <div className="title">
                                            <p>職種</p>
                                        </div>
                                        <div className="texts">
                                            <p>システム監視、不正監視など</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="title">
                                            <p>対象となる方</p>
                                            <p>資格</p>
                                        </div>

                                        <p className="texts">未経験者・異業種からの転職も大歓迎</p>
                                    </li>
                                    <li>
                                        <div className="title">
                                            <p>勤務地</p>
                                        </div>
                                        <p className="texts">〒060-0063 札幌市中央区南３条西１３丁目３２０－１３ アクアステージ４階</p>
                                    </li>
                                    <li>
                                        <div className="title">
                                            <p>雇用形態</p>
                                        </div>
                                        <p className="texts">正社員</p>
                                    </li>
                                    <li>
                                        <div className="title">
                                            <p>勤務形態</p>
                                        </div>
                                        <p className="texts">シフト制</p>
                                    </li>
                                    <li>
                                        <div className="title">
                                            <p>シフト詳細</p>
                                        </div>

                                        <p className="texts">★有給休暇の取得もしやすい環境です！</p>
                                    </li>
                                    <li>
                                        <div className="title">
                                            <p>採用予定人数</p>
                                        </div>
                                        <p className="texts">２名</p>
                                    </li>
                                    <li>
                                        <div className="title">
                                            <p>交通費詳細</p>
                                        </div>
                                        <p className="texts">
                                            全額支給 <br />
                                            ★地下鉄「西11丁目駅」より徒歩10分→駅チカで通勤ラクラクです◎
                                        </p>
                                    </li>
                                    <li>
                                        <div className="title">
                                            <p>福利厚生</p>
                                            <p>待遇</p>
                                        </div>
                                        <p className="texts">
                                            雇用保険・社保完備、有給休暇、深夜割増別途、服装・髪色自由
                                            <br />
                                            ※試用期間（最大2か月）
                                            <br />
                                            ★休憩室環境もバッチリ！ →冷蔵庫・電子レンジが設置されています♪
                                        </p>
                                    </li>
                                </ul>
                                <Link to="/support">
                                    <button className="btn-A">
                                        <span className="spn1">お問い合わせ</span>
                                        <span className="spn2">→</span>
                                    </button>
                                </Link>
                            </>
                        ) : (
                            <h3 className="noRecruiting">現在募集は行っておりません。</h3>
                        )}
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};
