import React, { useState, useEffect, useRef } from "react";
import GLOBE from "vanta/dist/vanta.globe.min";
import swiperbg from "../../../public/images/mainkeyVisualBG.png";

export const VantaGlobe = () => {
    const vantaBGcolor = 0xf0037;
    const [vantaEffect, setVantaEffect] = useState(null);
    const vanta1 = useRef(null);
    useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(
                GLOBE({
                    el: vanta1.current,
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 200.0,
                    minWidth: 200.0,
                    scale: 1.0,
                    scaleMobile: 1.0,
                    color: 0x5367a2,
                    color2: 0x93e9f5,
                    size: 0.5,
                    backgroundColor: vantaBGcolor,
                })
            );
        }
        return () => {
            if (vantaEffect) vantaEffect.destroy();
        };
    }, [vantaEffect]);
    return (
        <>
            <div ref={vanta1} className="vanta"></div>
            <img src={swiperbg} className="swiperBG" alt="keyVisualBG" />
        </>
    );
};
