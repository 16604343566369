import { Router } from "./Router";
import React, { useState, useEffect } from "react";

import "./App.css";

import AnimatedCursor from "react-animated-cursor";

import { Loader } from "./components/loader/Loader";
import { Auth } from "./components/auth/Auth";

const OPTIONS = {
    loaderTime: 1.6,
    routerLoadingTime: 1,
};

export const App = ({ AUTH, LOADING, debug }) => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [auth, setAuth] = useState(!AUTH);

    useEffect(() => {
        return () => {};
    }, []);

    return (
        <>
            {/* <AnimatedCursor
                innerSize={12}
                outerSize={12}
                color="100, 0, 200"
                outerAlpha={0.2}
                innerScale={0.7}
                outerScale={5}
                clickables={[
                    "a",
                    'input[type="text"]',
                    'input[type="email"]',
                    'input[type="number"]',
                    'input[type="submit"]',
                    'input[type="image"]',
                    "label[for]",
                    "select",
                    "textarea",
                    "button",
                    ".link",
                ]}
            /> */}
            {!auth ? (
                <Auth setAuth={setAuth} />
            ) : (
                <>
                    {!isLoaded && LOADING && <Loader loaderTime={OPTIONS.loaderTime} setIsLoaded={setIsLoaded}></Loader>}
                    <Router LOADING={LOADING} isLoaded={isLoaded} debug={debug}></Router>
                </>
            )}
        </>
    );
};
