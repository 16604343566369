import React, { useState,useRef } from "react";

export const Auth = ({setAuth}) => {

    const spanEl = useRef(null);
    const passwordEl = useRef(null);

    const handleAuthenticate = () => {
        if (passwordEl.current.value === "cs03") {
            setAuth(true);
        } else {
            spanEl.current.textContent ="エラー：パスワードが間違っています。"
        }
    };

    return (
        <div className="auth">
            <h2>パスワードを入力してください</h2>
            <div className="inputs">
                <input type="text" placeholder="パスワード" ref={passwordEl}/>
                <button onClick={handleAuthenticate}>認証</button>
            </div>
            <span ref={spanEl} className="invalided"></span>
        </div>
    );
};
