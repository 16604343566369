import React, { useState, useEffect, useRef } from "react";
import BIRDS from "vanta/dist/vanta.birds.min";
import swiperbg from "../../../public/images/mainkeyVisualBG.png";

export const VantaBird = () => {
    const vantaBGcolor = 0xf0037;
    const [vantaEffect, setVantaEffect] = useState(null);
    const vanta1 = useRef(null);
    useEffect(() => {
        if (!vantaEffect) {
            setVantaEffect(
                BIRDS({
                    el: vanta1.current,
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    quantity: 4.0,
                    minHeight: 200.0,
                    minWidth: 200.0,
                    scale: 1.0,
                    scaleMobile: 1.0,
                    color1: 0x2c23c5,
                    color2: 0xb3ff,
                    speedLimit: 4.0,
                    separation: 40.0,
                    cohesion: 22.0,
                    birdSize: 1.5,
                    backgroundAlpha: 0,
                    backgroundColor: vantaBGcolor,
                })
            );
        }
        return () => {
            if (vantaEffect) vantaEffect.destroy();
        };
    }, [vantaEffect]);
    return (
        <>
            <div ref={vanta1} className="vanta"></div>
            <img src={swiperbg} className="swiperBG" alt="keyVisualBG"/>
        </>
    );
};
