import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./src/App";
import { BrowserRouter } from "react-router-dom";

const OPTIONS = {
    debugTable:false,
    AUTH: false,
    LOADING: true,
    StrictMode: false,
};

//デバッグ用
const debugTable = {
    デバッグ用テーブル表示: OPTIONS.debugTable ? "有効" : "無効",
    簡易認証: OPTIONS.AUTH ? "有効" : "無効",
    ローディング画面: OPTIONS.LOADING ? "有効" : "無効",
    ストリクトモード: OPTIONS.StrictMode ? "有効" : "無効",
};
const debuging = (enable, place, table) => {
    if (enable) {
        console.log(`------${place}-------↓`);
        console.table(table);
    }
};
debuging(OPTIONS.debugTable,"index.js",debugTable)

const root = ReactDOM.createRoot(document.getElementById("root"));

const renderApp = (
    <BrowserRouter>
        <App AUTH={OPTIONS.AUTH} LOADING={OPTIONS.LOADING} debug={{enable:OPTIONS.debugTable,debuging:debuging}} />
    </BrowserRouter>
);

if (OPTIONS.StrictMode) {
    root.render(<React.StrictMode>{renderApp}</React.StrictMode>);
} else {
    root.render(renderApp);
}
