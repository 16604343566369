import React from "react";
import { LinkOut } from "akar-icons";

export const ManageLink = ({ title, url, description }) => {
    const shortUrl = shortenURL(url, 50);

    return (
        <a href={url} target="_blank" className="link">
            <div className="inner">
                <p className="title">{title}</p>
                <p className="url">{shortUrl}</p>
                <div className="description">{description}</div>
            </div>
            <LinkOut className="linkout" strokeWidth={2} size={15} />
        </a>
    );
    function shortenURL(url, maxLength) {
        if (url.length <= maxLength) {
            return url;
        } else {
            const ellipsis = "...";
            const prefixLength = maxLength - ellipsis.length;
            const prefix = url.slice(0, prefixLength);
            return prefix + ellipsis;
        }
    }
};
