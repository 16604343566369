import React, { useEffect, useRef } from "react";
import { PromptScroll } from "./PromptScroll";
import { Swiperr } from "../swiper/Swiperr";

export const KeyVisual = ({isLoaded}) => {
    const h1El = useRef(null);
    const pEl = useRef(null);
    
    const slideAnimeDelay = isLoaded ? 0.1 : 1;

    useEffect(() => {
        setTimeout(() => {
            h1El.current.classList.add("SlideInAnime");
            pEl.current.classList.add("SlideInAnime");
        }, slideAnimeDelay * 1000);
        return () => {};
    }, []);

    return (
        <div className="KeyVisual" id="top">
            <div className="inner">
                <div className="content">
                    <h1 ref={h1El}>Hospitality mind</h1>
                    <p ref={pEl}>
                        <span>「顧客満足度を高める」気持ちの在り方、</span>
                        <span className="nowrap">それは「サービス」の更に上を追求すること</span>
                    </p>
                </div>
            </div>

            <Swiperr></Swiperr>
            <PromptScroll></PromptScroll>
        </div>
    );
};
