import React, { useEffect } from "react";
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";
import { MoveTopBtn } from "../../components/moveTopBtn/MoveTopBtn";

export const PrivacyP = ({UNSCROLLABLE,setScrollTarget}) => {
    useEffect(() => {
        const headerElem = document.querySelector("header");
        headerElem.classList.add("scrolled");
        return () => {};
    }, []);

    return (
        <>
            <Header page={"privacy"} scrolledClass={true} setScrollTarget={setScrollTarget} UNSCROLLABLE={UNSCROLLABLE}></Header>
            <div className="pageContents privacyP">
                <div className="inner">
                    <h2 className="pageTitle">プライバシーポリシー</h2>
                    <div className="block">
                        <h2>個人情報保護方針</h2>
                        <p>
                            株式会社シーエスエス（以下「当社」といいます。）は、個人情報の保護を最も重要な責務と考え、次の方針を掲げ、これを徹底いたします。
                        </p>
                        <ul>
                            <li>当社は、個人情報の保護に関し、法令およびその他の規範を遵守のうえ個人情報を取り扱います。</li>
                            <li>
                                当社は、当社の事業活動に必要な範囲で個人情報など取得・利用・提供します。法令に定める場合を除き、あらかじめお客様の同意を得ることなくお客様の個人情報を第三者に提供いたしません。
                            </li>
                            <li>
                                当社は、個人情報への不正アクセス、個人情報の紛失、破壊、改ざんおよび漏えいの防止並びに是正を行うためのシステム、事務における安全対策を実行します。
                            </li>
                            <li>当社は、個人情報の保護に関する教育を全役職員に対して継続的に行い、個人情報の適切な取り扱いを実践いたします。</li>
                            <li>当社は、個人情報保護のためのマネジメントシステムの継続的改善をおこないます。</li>
                            <li>当社は、個人情報の開示・訂正・削除等の申し出等に関する相談、苦情に対して当社窓口にて適切な対応をいたします。</li>
                            <p className="shomei">
                                株式会社シーエスエス <br />
                                代表取締役　松原　久美子
                            </p>
                        </ul>
                    </div>
                    <div className="block">
                        <h2>個人情報の取り扱いについて</h2>
                        <ul>
                            <li>
                                <h3>１．個人情報の利用目的</h3>
                                <p>
                                    当社が取得する個人情報の利用目的は、以下のとおりです。ただし、取得時に提示した書面（WEBサイト含む）に利用目的が記載されている場合は、当該記載内容が利用目的となります。
                                </p>
                                <ul>
                                    <li>
                                        <h4>（１）ユーザー（※当社の各種サービスを利用する利用者）</h4>
                                        <ul>
                                            <li>・サービスの提供を行うため</li>
                                            <li>・サービスに関するお問い合わせに対応するため</li>
                                            <li>・各種ご案内、事務連絡、情報発信のため</li>
                                            <li>・上記に付随、関連する業務遂行のため</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h4>（２）取引先（※当社のサービスを導入する事業者又は提供会社）</h4>
                                        <ul>
                                            <li>・サービスの遂行のため</li>
                                            <li>・お問い合わせ対応、資料提供のため</li>
                                            <li>・契約締結、取引審査のため</li>
                                            <li>・各種ご案内、事務連絡、情報発信のため</li>
                                            <li>・上記に付随、関連する業務遂行のため</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h4>（３）役職員など（※当社役員・従業員（出向・派遣などを含む）及び応募者）</h4>
                                        <ul>
                                            <li>・採用選考及び面接などの連絡のため</li>
                                            <li>・人事管理、業務管理、セキュリティ管理のため</li>
                                            <li>・社会保険、労働保険手続きなど、法律上必要な手続きをおこなうため</li>
                                            <li>・上記に付随、関連する業務遂行のため</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <h3>２．個人情報の第三者提供</h3>
                                <p>当社は、以下の場合を除き、取得した個人情報を第三者に提供することはありません。</p>
                                <ul>
                                    <li>・ご本人の同意がある場合</li>
                                    <li>・各種法令に基づく場合</li>
                                    <li>・外部へ委託する場合</li>
                                </ul>
                            </li>
                            <li>
                                <h3>３．個人情報の開示・訂正・利用停止などの請求について</h3>
                                <p>
                                    ご本人またはその代理人（以下、「ご本人など」といいます）は、ご本人の個人情報について
                                    開示など（利用目的の通知、開示、内容の訂正・追加・削除、利用の停止・消去、第三者への提供の窓口まで
                                    ご連絡ください。
                                </p>
                                <p>
                                    個人情報について開示など求める場合には下記の個人情報お問合せ窓口までご連絡ください。
                                    個人情報について開示など求める場合の手続きの詳細についてのご案内いたします。
                                    <br />
                                    当社は、所定の手続きによりご本人などから個人情報について開示を求められた場合、適切な範囲で速やかに対応いたします。
                                </p>
                            </li>
                            <li>
                                <h3>４．個人情報の取扱いに関するご相談・苦情について</h3>
                                <p>個人情報取扱いに関する苦情及び相談などのお問合せは、下記の窓口で受け付けております。</p>
                                <ul className="addressUl">
                                    <li>・会社名：株式会社シーエスエス</li>
                                    <li>・住　所：札幌市中央区南3条西13丁目320-13-4F</li>
                                    <li>・電　話：011-211-0740</li>
                                    <li>・メール：info@css-sp.jp</li>
                                </ul>
                            </li>
                            <li>
                                <h3>５．プライバシーポリシー（個人情報保護方針）の改定について</h3>
                                <p>
                                    弊社は、プライバシーポリシーを適宜見直し、改善・改定いたします。
                                    <br />
                                    改定後のプライバシーポリシーはすみやかに掲載いたします。
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <MoveTopBtn></MoveTopBtn>
            <Footer></Footer>
        </>
    );
};
