import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";
import { ManageLink } from "./ManageLink";

export const ManageP = ({ UNSCROLLABLE }) => {
    return (
        <>
            <Header page={"manage"} scrolledClass={true} UNSCROLLABLE={UNSCROLLABLE}></Header>
            <div className="Manage">
                <div className="inner">
                    <h2 className="pageTitle">管理用ページ</h2>
                    <div className="links">
                        <ManageLink
                            title="Google Cloud Console"
                            url={"https://console.cloud.google.com/apis/credentials?project=numeric-arena-403304&authuser=2"}
                            description={"APIキーの管理ができます。"}
                        ></ManageLink>
                        <ManageLink
                            title="Github"
                            url="https://github.com/css-github-hp/homepage"
                            description="リモートリポジトリを管理できます。"
                        ></ManageLink>
                        <ManageLink
                            title="Netlify"
                            url="https://app.netlify.com/teams/css-github-hp/overview"
                            description="デプロイ関係を管理できます。"
                        ></ManageLink>
                        <ManageLink
                            title="EmailJS"
                            url="https://dashboard.emailjs.com/sign-in"
                            description="お問い合わせメール送信関係を管理できます。"
                        ></ManageLink>
                    </div>
                    <Link to="/">
                        <button className="btn-A">
                            <span className="spn1">トップページ</span>
                            <span className="spn2">→</span>
                        </button>
                    </Link>
                </div>
            </div>
            <Footer></Footer>
        </>
    );
};
