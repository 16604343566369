import React from "react";
import { SectionTitle } from "./SectionTitle";

export const HomeSec = () => {
    return (
        <section className="home" id="home">
            <div className="inner setV">
                <SectionTitle titleName="株式会社シーエスエスは" sideLine={true}></SectionTitle>

                <p>
                    顧客満足度を第一に考え、クライアント様の求めるものを
                    <br />
                    カタチにするお手伝いが出来るよう努めてまいります。
                </p>
                <p>
                    <span className="bold">
                        「案件に合わせて臨機応変に対応して欲しい」
                        <br />
                        「 クレームを迅速に対応して欲しい 」<br />
                        「サイト監視だけに人手をさけない」など
                        <br />
                    </span>
                    ございませんか？
                </p>
                <p>
                    コールセンターやデータ監視でお困りの企業さま
                    <br />
                    一度弊社にご要望をお聞かせいただけませんでしょうか。
                </p>
                <p>
                    お客様の業種や経営方針など、さまざまなご要望に合わせて
                    <br />
                    柔軟性のある対応を心掛けてまいりますので、ご納得いただけるよう
                    <br />
                    最適なプランをご提案いたします。
                    <br />
                    どうぞお気軽にお問合せくださいませ。 <br />
                </p>
            </div>
        </section>
    );
};
