import React from "react";
import { Link } from "react-router-dom";
import bg from "../../../public/images/keyvisual/recruit.jpg";
import { SectionTitle } from "./SectionTitle";

export const RecruitSec = () => {
    return (
        <section className="recruit" id="recruit">
            <div className="inner setV">
                <SectionTitle titleName="採用情報" sideLine={true}></SectionTitle>
                <Link to="/recruit">
                    <button className="btn-A">
                        <span className="spn1">採用情報を確認</span>
                        <span className="spn2">→</span>
                    </button>
                </Link>
            </div>
            <img src={bg} alt="" className="bg js-rellax" data-rellax-percentage="0.5" />
        </section>
    );
};
